.inventory-line {
  fill: none;
  stroke: white;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.inventory-dot {
  fill: white;
}
