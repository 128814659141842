.react-autosuggest__container {
  position: relative;
  box-sizing: border-box;
  flex: 0 0 auto;
  width: 70%;
  border: 1px solid rgb(195, 195, 195);
  border-radius: 25px;
}

.react-autosuggest__container--open {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.react-autosuggest__input {
  font-family: Helvetica, sans-serif;
  font-weight: 300;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgb(195, 195, 195);
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-top: 1px solid rgb(200, 200, 200);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  color: #666;
  padding: 10px 20px;
}
.react-autosuggest__suggestion:last-child {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #F2F2F2;
}