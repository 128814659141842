.logoIcon-a {
  fill:#fff;
}
.logoIcon-b{
  fill:url(#a);
}
.logoIcon-c{
  fill:#23bbec;
}
.logoIcon-d{
  fill:#1a99ce;
}