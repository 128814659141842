.logoIcon-text {
  fill: #3f3f3f;
}
.logoIcon-b{
  fill: url(#a);
}
.logoIcon-c{
  fill: #23bbec;
}
.logoIcon-d{
  fill: #1a99ce;
}
