
.operations-1, .operations-2 {
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
}

.operations-1 {
  stroke-linecap: square;
}

.create-icon-1, .create-icon-2 {
  fill: none;
  stroke: #474747;
  stroke-miterlimit: 10;
}

.create-icon-1 {
  stroke-linecap: square;
}