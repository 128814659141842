body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

h1, h2, h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

#root {
  width: 100%;
  height: 100%;
}

#page.primary-layout {
  display: flex;
}

.primary-layout #sidebar {
  flex: 0 0 auto;
}

.primary-layout #page__content {
  flex: 1 1 auto;
  width: 0;
}

.full-root {
  width: 100%;
  min-height: 100%;
}

input::placeholder {
  color: #CCC;
  font-weight: 200;
}
