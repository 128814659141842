.container {
  background-color: white;
  height: 100%;
  padding: 48px;
}

.wrapper {
  margin-bottom: 32px;
  display: block;
  float: left;
  width: 900px;
}

.title {
  color: #189ACA;
  margin-bottom: 8px;
}

.metric {
  display: flex;
}

.label {
  flex-basis: 200px;
  color: rgb(158, 158, 158);
  margin-bottom: 8px;
}

.value {
  flex-basis: auto;
  flex-grow: 1;
}

@media only screen and (max-width: 1600px) {
  .container {
    height: 100%;
  }

  .wrapper {
    width: 700px;
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    width: 552px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .metric {
    display: block;
    float: left;
    width: 327px;
    font-size: 15px;
    margin-bottom: 8px;
  }

  .container {
    height: 100%;
  }

  .wrapper {
    width: 327px;
  }
}
